import { Component, inject } from "@angular/core";
import { FdRouterService } from "@fd/core";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrl: "./not-found.component.scss",
})
export class NotFoundComponent {
  private readonly fdRouterService = inject(FdRouterService);

  get previousUrl() {
    // allow user to go to "previous page" only if one exists and it isn't the home page.
    return this.fdRouterService.previousUrl && this.fdRouterService.previousUrl !== "/"
      ? this.fdRouterService.previousUrl
      : undefined;
  }
}
