/**
 *  Client version of Repository/Infrastructure/UserRoles.cs
 *  TODO: Code generation - for now, we will have to manually update both files so that they match
 */
export enum UserRoles {
  System = "System",

  Admin = "Admin",
  Admin_Users_View = "Admin_Users_View",
  Admin_Users_Edit = "Admin_Users_Edit",
  Admin_SecurityGroups_View = "Admin_SecurityGroups_View",
  Admin_SecurityGroups_Edit = "Admin_SecurityGroups_Edit",
  Admin_Localization_View = "Admin_Localization_View",
  Admin_Localization_Edit = "Admin_Localization_Edit",
  Admin_ImpersonateUser = "Admin_ImpersonateUser",
  Admin_ManageAccessList = "Admin_ManageAccessList",
  Admin_ManageGlobalData = "Admin_ManageGlobalData",
  Admin_ViewAllCustomerData = "Admin_ViewAllCustomerData",
  Admin_Plants_View = "Admin_Plants_View",
  Admin_Plants_Edit = "Admin_Plants_Edit",
  Admin_Casters_View = "Admin_Casters_View",
  Admin_Casters_Edit = "Admin_Casters_Edit",
  Admin_Casters_TruncateDate = "Admin_Casters_TruncateDate",
  Admin_Casters_ConfigureDatabase = "Admin_Casters_ConfigureDatabase",
  Admin_Metrics_View = "Admin_Metrics_View",
  Admin_Metrics_Edit = "Admin_Metrics_Edit",
  Admin_MetricLiveData_View = "Admin_MetricLiveData_View",
  Admin_MetricLiveData_Edit = "Admin_MetricLiveData_Edit",
  Admin_GradeFamilies_View = "Admin_GradeFamilies_View",
  Admin_GradeFamilies_Edit = "Admin_GradeFamilies_Edit",
  Admin_DataFetcher_View = "Admin_DataFetcher_View",
  Admin_DataFetcher_Edit = "Admin_DataFetcher_Edit",
  Admin_CustomerGroups_View = "Admin_CustomerGroups_View",
  Admin_CustomerGroups_Edit = "Admin_CustomerGroups_Edit",
  Admin_IPAddressWhitelist_View = "Admin_IPAddressWhitelist_View",
  Admin_IPAddressWhitelist_Edit = "Admin_IPAddressWhitelist_Edit",

  Insights = "Insights",
  Insights_Download_View = "Insights_Download_View",
  Insights_Download_Edit = "Insights_Download_Edit",

  Dashboard = "Dashboard",
  Dashboard_Caster_View = "Dashboard_Caster_View",
  Dashboard_Caster_Edit = "Dashboard_Caster_Edit",
  Dashboard_Dial_View = "Dashboard_Dial_View",
  Dashboard_Dial_Edit = "Dashboard_Dial_Edit",
  Dashboard_SimulateData = "Dashboard_SimulateData",

  Event = "Event",
  Event_Reasons_View = "Event_Reasons_View",
  Event_Reasons_Edit = "Event_Reasons_Edit",
  Event_Shifts_View = "Event_Shifts_View",
  Event_Shifts_Edit = "Event_Shifts_Edit",
  Event_CasterEvents_View = "Event_CasterEvents_View",
  Event_CasterEvents_Edit = "Event_CasterEvents_Edit",
  Event_CasterEvents_AllowConflictingData = "Event_CasterEvents_AllowConflictingData",

  Aggregation = "Aggregation",
  Aggregation_CasterMetricHistory_View = "Aggregation_CasterMetricHistory_View",
  Aggregation_CasterMetricHistory_Edit = "Aggregation_CasterMetricHistory_Edit",
  Aggregation_MoldSizeSetting_View = "Aggregation_MoldSizeSetting_View",
  Aggregation_MoldSizeSetting_Edit = "Aggregation_MoldSizeSetting_Edit",
}
