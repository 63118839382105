<mat-toolbar [color]="serverEnvironment !== AppEnvironment.Production ? 'primary' : 'accent'" class="mat-elevation-z1">
  <div *ngIf="userContext">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <span class="app-title d-none d-sm-inline-block">{{ appTitle }}</span>
  <span class="spacer"></span>
  <a routerLink="/">
    <img src="assets/images/logo-white.png" class="d-inline-block align-top logo" alt="{{ appTitle }}" />
  </a>

  <ng-container *ngIf="clientEnvironment !== AppEnvironment.Production">
    <span class="spacer"></span>
    <app-environment-chip [clientEnvironment]="clientEnvironment" [serverEnvironment]="serverEnvironment" />
  </ng-container>

  <ng-container *ngIf="userContext">
    <span class="spacer"></span>

    <mat-form-field
      *ngIf="fdStateService.showCasters"
      class="caster flex-grow-1 d-none d-md-inline-block mat-dense mt-2"
      appearance="outline">
      <mat-label>Caster</mat-label>
      <mat-select matInput [(value)]="fdStateService.caster" (selectionChange)="fdStateService.casterChanged()">
        <mat-option *ngFor="let item of fdStateService.$casters | async" [value]="item">
          {{ this.userContext.isInRole(this.UserRoles.Admin_ViewAllCustomerData) ? item.nameWithPrefix : item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      class="flex-row gap-2 queryMinutes d-none d-md-flex mat-dense mt-2"
      *ngIf="fdStateService.showDashboardOptions">
      <mat-form-field appearance="outline">
        <mat-label>Chart Period</mat-label>
        <mat-select
          matInput
          [(value)]="fdStateService.queryMinutes"
          (selectionChange)="fdStateService.queryMinutesChanged()">
          <mat-option *ngFor="let item of queryMinutes" [value]="item">{{ item }} Minutes</mat-option>
          <mat-option #queryMinutesInput value="{{ fdStateService.queryMinutes }}">Custom</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="isCustom" appearance="outline">
        <mat-label>Custom</mat-label>
        <input
          #customInput
          matInput
          type="text"
          [value]="fdStateService.queryMinutes"
          (change)="queryMinutesChanged($event)" />
        <span matSuffix>Minutes</span>
      </mat-form-field>
    </div>

    <button
      *ngIf="isImpersonatingUser"
      class="d-none d-sm-block"
      mat-flat-button
      color="warn"
      matTooltip="Revert Impersonation"
      (click)="abortImpersonate()">
      Impersonating {{ fullName }}
    </button>

    <button mat-button [matMenuTriggerFor]="userMenu" class="pe-0">
      <span class="d-flex align-items-center">
        {{ fullName }}
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>

    <mat-menu #userMenu xPosition="before">
      @if (themeService.activeTheme === "dark") {
        <button mat-menu-item (click)="themeService.toggleTheme()">
          <mat-icon>light_mode</mat-icon>
          Light Mode
        </button>
      } @else {
        <button mat-menu-item (click)="themeService.toggleTheme()">
          <mat-icon>dark_mode</mat-icon>
          Dark Mode
        </button>
      }
      <button mat-menu-item (click)="openSettings()">
        <mat-icon>settings</mat-icon>
        Page Options
      </button>
      <a mat-menu-item [routerLink]="AppRoutes.account.profile.routerLink">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
      </a>
      <a mat-menu-item [routerLink]="AppRoutes.account.userPreferences.routerLink">
        <mat-icon>account_circle</mat-icon>
        <span>User Preferences</span>
      </a>
      <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>

      <ng-container
        *ngIf="
          environment.configuration !== AppEnvironment.Production && environment.configuration !== AppEnvironment.Beta
        ">
        <mat-divider />

        <h3 class="roles-title" (click)="$event.stopPropagation()">Impersonate Roles</h3>

        <button
          *ngFor="let role of applicableRoles"
          mat-menu-item
          (click)="impersonateRole(false, role)"
          matTooltipPosition="left">
          <mat-icon>{{ userContext.isInRole(role) ? "check_box" : "check_box_outline_blank" }}</mat-icon>
          <span>{{ role }}</span>
        </button>

        <button
          mat-menu-item
          (click)="impersonateRole(true)"
          matTooltip="This option will show up whether or not you are actively impersonating any roles.">
          <span>Reset Role Impersonation</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="showLogin">
    <span class="spacer"></span>

    <button mat-button routerLinkActive="active" [routerLink]="AppRoutes.login.routerLink">
      <mat-icon>login</mat-icon>
      Log In
    </button>
  </ng-container>
</mat-toolbar>
