import { prop, required } from "@rxweb/reactive-form-validators";

export class AccessListItem implements AccessListInterface {
  @prop()
  ip: string;
  @required()
  comment: string;
  @prop()
  status: string;

  public constructor(init?: Partial<AccessListItem>) {
    Object.assign(this, init);
  }
}

export interface AccessListInterface {
  ip: string;
  comment: string;
  status: string;
}
