<h3>Network Access</h3>

<div class="d-flex" [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Access Status</mat-label>
    <input matInput formControlName="status" [readonly]="true" />
    <mat-error>
      {{ formGroup.controls.status.errorMessage }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>IP Address</mat-label>
    <input matInput formControlName="ip" />
    <mat-error>
      {{ formGroup.controls.ip.errorMessage }}
    </mat-error>
  </mat-form-field>
</div>

<div *ngIf="userContext.isInRole(UserRoles.Admin_ManageAccessList)">
  <form
    *ngIf="item.status === 'Revoked'"
    [formGroup]="formGroup"
    (submit)="submit()"
    (keyup.enter)="submit()"
    class="row flex-row gy-2 col-md-4 col-12">
    <mat-form-field>
      <mat-label>Comment</mat-label>
      <input matInput formControlName="comment" />
      <mat-error>
        {{ formGroup.controls.comment.errorMessage }}
      </mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" [disabled]="formGroup.invalid || formGroup.pristine">Grant Access</button>
  </form>

  <button *ngIf="item.status === 'Active'" mat-raised-button (click)="delete()" color="primary">Revoke Access</button>
</div>
