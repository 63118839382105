import { Injectable } from "@angular/core";
import { FdBaseComponent } from "@fd/core";
import { IAppEnvironment } from "src/environments/environment.definition";
import { UserDtoModel } from "../modules/account/models/user-dto.model";
import { UserRoles } from "../modules/shared/enums/user-roles.enum";
import { AuthService } from "../modules/shared/services/auth.service";
import { AppRoutes } from "../routes";

@Injectable()
export abstract class BaseComponent extends FdBaseComponent<UserDtoModel, AuthService> {
  protected readonly AppRoutes = AppRoutes;
  protected readonly UserRoles = UserRoles;
  override readonly environment: IAppEnvironment;
}
