<h2 mat-dialog-title>Page Options</h2>
<mat-dialog-content>
  <div class="form-field-layout">
    <mat-form-field *ngIf="fdStateService.showCasters">
      <mat-label>Caster</mat-label>
      <mat-select matInput [(value)]="fdStateService.caster" (selectionChange)="fdStateService.casterChanged()">
        <mat-option *ngFor="let item of fdStateService.$casters | async" [value]="item">
          {{ this.userContext.isInRole(this.UserRoles.Admin_ViewAllCustomerData) ? item.nameWithPrefix : item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="fdStateService.showDashboardOptions">
      <mat-form-field>
        <mat-label>Chart Period</mat-label>
        <mat-select
          matInput
          [(value)]="fdStateService.queryMinutes"
          (selectionChange)="fdStateService.queryMinutesChanged()">
          <mat-option *ngFor="let item of queryMinutes" [value]="item">{{ item }} Minutes</mat-option>
          <mat-option #queryMinutesInput value="{{ fdStateService.queryMinutes }}">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isCustom">
        <mat-label>Custom</mat-label>
        <input
          #customInput
          matInput
          type="text"
          [value]="fdStateService.queryMinutes"
          (change)="queryMinutesChanged($event)" />
        <span matSuffix>Minutes</span>
      </mat-form-field>
    </ng-container>

    <div *ngIf="fdStateService.showDashboardOptions" class="mb-2">
      <mat-slide-toggle [checked]="fdStateService.showOutliers" (toggleChange)="fdStateService.showOutliersChanged()">
        Show Outliers (Box Plots)
      </mat-slide-toggle>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" cdkFocusInitial>Close</button>
</mat-dialog-actions>
