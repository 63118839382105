import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// Prototyping
// todo: these should be added by the core module
import { AppEnvironment } from "@fd/core";
import "./app/prototypes/array";
import "./app/prototypes/date";
import "./app/prototypes/element";
import "./app/prototypes/number";
if (environment.configuration === AppEnvironment.Production || environment.configuration === AppEnvironment.Beta) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
