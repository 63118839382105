import { Component, OnInit, inject } from "@angular/core";
import { FdRouterService, FdTitleService, GlobalLoadingIndicatorService, LocalStoreManager } from "@fd/core";
import { OnlineStatusService } from "src/app/modules/shared/services/online-status.service";
import { SwUpdateService } from "../services/sw-update.service";
import { BaseComponent } from "./base.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent extends BaseComponent implements OnInit {
  private readonly appTitleService = inject(FdTitleService);
  private readonly globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private readonly onlineStatusService = inject(OnlineStatusService);
  protected readonly storageManager = inject(LocalStoreManager);
  readonly fdRouterService = inject(FdRouterService);
  readonly swUpdateService = inject(SwUpdateService);

  showGlobalLoadingIndicator = false;
  isLoadingLazyModule = false;
  appTitle = document.title;

  ngOnInit() {
    this.storageManager.initializeStorageSyncListener();

    this.appTitleService.appName = this.appTitle;

    // subscribe to routing events to trigger a busy spinner on loading a lazy module
    this.fdRouterService.routeConfigLoadStart$.subscribe(() => (this.isLoadingLazyModule = true));
    this.fdRouterService.routeConfigLoadEnd$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationCancel$.subscribe(() => (this.isLoadingLazyModule = false));
    this.fdRouterService.navigationError$.subscribe(() => (this.isLoadingLazyModule = false));

    // subscribe to loading events to trigger a busy progress bar
    this.globalLoadingIndicatorService.isLoading$.subscribe(isLoading =>
      setTimeout(() => (this.showGlobalLoadingIndicator = isLoading))
    );

    // Begin tracking network connectivity status
    this.onlineStatusService.initialize();
    this.swUpdateService.initialize();
  }
}
