<div class="container-xs py-4">
  <div class="surface">
    <h1 class="mat-h1 mb-0 text-center fw-bold">Login to your account</h1>
    <!-- Due to the nature of forms in Angular applications, we need to post this form using the .form() function -->
    <form
      *ngIf="showMicrosoftLogin || showGoogleLogin"
      #externalSignIn
      action="/api/Account/Account/SignInWithProvider"
      method="post"
      class="mt-4">
      <input #provider type="hidden" name="Provider" />
      <div class="flex-spaced">
        <button
          (click)="provider.value = 'Microsoft'; externalSignIn.submit()"
          *ngIf="showMicrosoftLogin"
          class="provider"
          mat-stroked-button
          type="submit"
          [disabled]="isLoading">
          <span class="d-flex align-items-center">
            <img src="/assets/images/login/ms-logo.png" class="me-2" />
            Continue with Microsoft
          </span>
        </button>

        <button
          (click)="provider.value = 'Google'; externalSignIn.submit()"
          *ngIf="showGoogleLogin"
          class="provider"
          mat-stroked-button
          type="submit"
          [disabled]="isLoading">
          <span class="d-flex align-items-center">
            <img src="/assets/images/login/g-logo.png" class="me-2" />
            Continue with Google
          </span>
        </button>
      </div>
      <p class="mt-3 d-flex justify-content-center">
        <mat-checkbox
          *ngIf="!showLocalLogin && showRememberMe"
          [(ngModel)]="userLogin.rememberMe"
          (change)="rememberMeChange()"
          name="rememberMe">
          Remember me
        </mat-checkbox>
      </p>
    </form>

    <div *ngIf="showLocalLogin">
      <p *ngIf="showMicrosoftLogin || showGoogleLogin" class="mt-4 text-muted p-divider">
        <span>Or continue with</span>
      </p>
      <form
        *ngIf="formResetToggle"
        dirtyIgnore
        class="d-flex flex-column mt-4"
        name="loginForm"
        #f="ngForm"
        novalidate
        (ngSubmit)="
          f.form.valid ? signIn() : !username.valid && showErrorAlert('Please enter a valid username');
          !password.valid && showErrorAlert('Please enter a valid password')
        ">
        <mat-form-field hideRequiredMarker="true">
          <mat-label>
            <mat-icon>person</mat-icon>
            Username
          </mat-label>
          <input
            matInput
            type="text"
            id="login-username"
            name="username"
            placeholder="Enter username"
            autocomplete="username"
            [(ngModel)]="userLogin.userName"
            #username="ngModel"
            required />
          <mat-error>Username is required</mat-error>
        </mat-form-field>

        <mat-form-field hideRequiredMarker="true">
          <mat-label>
            <mat-icon>lock</mat-icon>
            Password
          </mat-label>
          <input
            matInput
            type="password"
            id="login-password"
            name="password"
            placeholder="Enter password"
            autocomplete="current-password"
            [(ngModel)]="userLogin.password"
            #password="ngModel"
            required />
          <mat-error>Password is required</mat-error>
        </mat-form-field>

        <div class="d-flex flex-wrap justify-content-between align-items-center py-2">
          <mat-checkbox
            *ngIf="showRememberMe"
            [(ngModel)]="userLogin.rememberMe"
            (change)="rememberMeChange()"
            name="rememberMe">
            Remember me
          </mat-checkbox>
          <a
            *ngIf="showForgotPassword"
            mat-button
            class="align-self-end"
            [routerLink]="AppRoutes.account.forgotPassword.routerLink">
            Forgot password?
          </a>
        </div>

        <button class="signin mt-3" mat-flat-button color="primary" type="submit" [disabled]="isLoading">
          <fd-loading-label [isLoading]="isLoading">Login</fd-loading-label>
        </button>
      </form>
    </div>
    <button
      *ngIf="isDevelopment"
      mat-button
      color="accent"
      (click)="signIn(true)"
      class="w-100 mt-4"
      [disabled]="isLoading">
      Developer Backdoor
    </button>
  </div>

  <a
    *ngIf="showRegister"
    mat-button
    color="accent"
    class="w-100 mt-4"
    [routerLink]="AppRoutes.account.registration.routerLink">
    Register for an account
  </a>
</div>
