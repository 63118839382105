import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { CasterInterface } from "../models/caster.model";

@Injectable({ providedIn: "root" })
export class CasterApiService extends ControllerBase<CasterInterface> {
  constructor() {
    super("Admin/Caster");
  }

  //#region super implementation
  get(
    customerGroupId?: number,
    plantId?: number,
    userId?: number,
    showInactive: boolean = false
  ): Observable<CasterInterface[]> {
    return super._get({ query: { customerGroupId, plantId, userId, showInactive } });
  }

  getById(id: number) {
    return super.$getById(id);
  }

  post(model: CasterInterface) {
    return super.$post(model);
  }

  put(model: CasterInterface) {
    return super.$put(model);
  }

  delete(id: number) {
    return super.$delete(id);
  }
  //#endregion

  truncate(id: number, startDate: Date, endDate: Date): Observable<number> {
    return super._delete(id, { url: this.truncate.name, query: { startDate, endDate } });
  }

  getDatabases(): Observable<string[]> {
    return super._get({ url: this.getDatabases.name });
  }
}
