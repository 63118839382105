<h2 mat-dialog-title>There is a new version ready!</h2>

<mat-dialog-content>
  <p>A new version of {{ titleService.appName }} has been published and is ready for you to use.</p>
  <div class="text-muted">
    If you choose to ignore this version, then there is a chance you will experience technical issues such as:
    <ol>
      <li>Unrecoverable server errors</li>
      <li>Unexpected data</li>
      <li>Inability to use new version enhancements</li>
    </ol>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" color="warn" [disabled]="updating">Ignore Update</button>
  <button mat-button color="primary" [disabled]="updating" cdkFocusInitial (click)="update()">
    Update and Refresh ({{ $countDown | async }}s)
  </button>
</mat-dialog-actions>
