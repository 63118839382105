import { AfterViewInit, Component, Inject, OnDestroy, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BackdropClass, FdTitleService, GlobalLoadingIndicatorService } from "@fd/core";
import { Observable, Subscription, timer } from "rxjs";
import { filter, map, takeWhile } from "rxjs/operators";
import { BaseDialogComponent } from "../base-dialog.component";

@Component({
  templateUrl: "./update-dialog.component.html",
  styleUrls: ["./update-dialog.component.scss"],
})
export class UpdateDialogComponent extends BaseDialogComponent implements AfterViewInit, OnDestroy {
  private detonation: Subscription;
  private readonly gliService = inject(GlobalLoadingIndicatorService);
  protected readonly titleService = inject(FdTitleService);

  updating = false;

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  /** Count down from 60 seconds */
  $countDown = timer(0, 1000).pipe(
    map(second => 60 - second),
    takeWhile(value => value >= 0)
  );

  constructor(@Inject(MAT_DIALOG_DATA) public activationReady: Observable<void>) {
    super();
  }

  ngAfterViewInit() {
    // start the count down after the view has been initialized
    this.detonation = this.$countDown.pipe(filter(x => x === 0)).subscribe(() => this.update());
  }

  ngOnDestroy(): void {
    this.detonation?.unsubscribe();
  }

  update() {
    this.updating = true;
    this.gliService.startEvent();

    this.activationReady.subscribe(() => document.location.reload());
  }
}
