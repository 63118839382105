<div *ngIf="link.children && link.showInMenu" [hidden]="!hasAccess">
  <ng-container *ngIf="link.label; else flat">
    <mat-expansion-panel dense class="mat-elevation-z0 px-0" [expanded]="isActive">
      <mat-expansion-panel-header [class.active]="isActive" #panel>
        <span class="level" style="--level: {{ level }}">{{ link.label }}</span>
      </mat-expansion-panel-header>

      <mat-nav-list dense class="pt-0">
        <app-nested-nav-item
          *ngFor="let childLink of link.children"
          [level]="level + 1"
          [link]="childLink"
          [drawer]="drawer" />
      </mat-nav-list>
    </mat-expansion-panel>
  </ng-container>
  <ng-template #flat>
    <app-nested-nav-item *ngFor="let childLink of link.children" [level]="level" [link]="childLink" [drawer]="drawer" />
  </ng-template>
</div>

<app-nav-item *ngIf="!link.children" [link]="link" [level]="level" [drawer]="drawer" [isHidden]="!hasAccess" />
