import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { UserRoles } from "../../shared/enums/user-roles.enum";
import { RegistrationModel } from "../components/registration/registration.model";
import { ApplicationIdentityUser } from "../models/application-identity-user.model";
import { ProfileDtoInterface } from "../models/profile-dto.model";
import { UserDtoInterface } from "../models/user-dto.model";

@Injectable({ providedIn: "root" })
export class AccountApiService extends ControllerBase<UserDtoInterface> {
  constructor() {
    super("Account/Account");
  }

  /** Signs user into server. If successful, the user is returned from the server. */
  signIn(userName: string, password: string, rememberMe?: boolean, backdoor?: boolean) {
    return this._post<UserDtoInterface>(
      {
        userName,
        password,
        rememberMe,
        backdoor,
      },
      { url: "SignIn" }
    );
  }

  /** Signs user out of server. */
  signOut() {
    return this._post(null, { url: "LogOut" });
  }

  /** Returns currently signed in user. */
  me<T extends UserDtoInterface | ApplicationIdentityUser | ProfileDtoInterface = UserDtoInterface>() {
    return this._get<T>({ url: "me" });
  }

  register(model: RegistrationModel): Observable<UserDtoInterface> {
    return this._post(model, {
      url: "Register",
    });
  }

  confirmEmail(emailAddress: string, code: string): Observable<UserDtoInterface> {
    return this._post(null, {
      url: "ConfirmEmail",
      query: { emailAddress, code },
    });
  }

  resendConfirmEmail(): Observable<boolean> {
    return this._post(null, {
      url: "ResendConfirmEmail",
    });
  }

  forgotPassword(emailAddress: string): Observable<boolean> {
    return this._post(null, {
      url: "ForgotPassword",
      query: { emailAddress },
    });
  }

  resetPassword(emailAddress: string, password: string, code: string): Observable<boolean> {
    const body = {
      emailAddress,
      password,
      code,
    };

    return this._post(body, { url: "ResetPassword" });
  }

  impersonateRole(reset: boolean, role?: UserRoles) {
    return this._post<UserDtoInterface>(null, {
      url: "ImpersonateRole",
      query: { reset, role },
    });
  }

  impersonate(identityId: string) {
    return this._post<UserDtoInterface>(null, {
      url: "Impersonate",
      query: { identityId },
    });
  }

  abortImpersonate() {
    return this._post<UserDtoInterface>(null, {
      url: "AbortImpersonate",
    });
  }

  updateProfile(user: ProfileDtoInterface): Observable<boolean> {
    return this._post(user, { url: "UpdateProfile" });
  }
}
