import { Component, OnInit, ViewChild } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { BackdropClass } from "@fd/core";
import { CasterInterface } from "src/app/modules/admin/models/caster.model";
import { FdStateService } from "src/app/services/fd-state.service";
import { BaseDialogComponent } from "../base-dialog.component";

@Component({
  selector: "app-options-dialog",
  templateUrl: "./options-dialog.component.html",
  styleUrls: ["./options-dialog.component.scss"],
})
export class OptionsDialogComponent extends BaseDialogComponent implements OnInit {
  @ViewChild("queryMinutesInput", { static: false }) queryMinutesInput: MatOption;

  static readonly hashId = "options";
  readonly queryMinutes = [5, 10, 30];
  casters: CasterInterface[];

  get isCustom() {
    return !this.queryMinutes.includes(this.fdStateService.queryMinutes);
  }

  protected get backdropClass(): BackdropClass {
    return "intrinsic";
  }

  constructor(public fdStateService: FdStateService) {
    super();
  }

  ngOnInit(): void {
    // This delay is not desired but the #queryMinutesInput template reference isn't available due to the ngIf
    setTimeout(() => this.overrideQueryMinutes(), 500);
  }

  queryMinutesChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = Math.max(Math.min(parseInt(target.value), 480), 1);
    if (value) {
      this.fdStateService.queryMinutes = value;
      this.overrideQueryMinutes();
      this.fdStateService.queryMinutesChanged();
    } else {
      target.value = this.fdStateService.queryMinutes.toString();
    }
  }

  // We are setting this so that the custom selction is retained.
  private overrideQueryMinutes() {
    if (this.isCustom && this.queryMinutesInput != undefined) {
      this.queryMinutesInput.value = this.fdStateService.queryMinutes;
      this.queryMinutesInput.select();
    }
  }
}
