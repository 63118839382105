import { Component, Input, OnInit, ViewChild, inject } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { AppEnvironment, FdRouterService, LocalStoreManager } from "@fd/core";
import { CookieService } from "ngx-cookie-service";
import { Subscription, of } from "rxjs";
import { catchError, filter, map, mergeMap, shareReplay, tap } from "rxjs/operators";
import { AccountHubService } from "src/app/modules/account/services/account-hub.service";
import { CasterApiService } from "src/app/modules/admin/services/caster-api.service";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";
import { UserRoles } from "src/app/modules/shared/enums/user-roles.enum";
import { AppRoutes, RouteInfoData } from "src/app/routes";
import { FdStateService } from "src/app/services/fd-state.service";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base.component";
import { OptionsDialogComponent } from "../options-dialog/options-dialog.component";
import { ThemeService } from "src/app/modules/shared/services/theme.service";

@Component({
  selector: "app-toolbar",
  templateUrl: "./app-toolbar.component.html",
  styleUrl: "./app-toolbar.component.scss",
})
export class AppToolbarComponent extends BaseComponent implements OnInit {
  @Input() drawer: MatSidenav;
  @Input() appTitle: string;

  private readonly accountHubService = inject(AccountHubService);
  private readonly cookieService = inject(CookieService);
  private readonly localStorage = inject(LocalStoreManager);
  private readonly router = inject(Router);
  private readonly fdRouterService = inject(FdRouterService);
  protected readonly fdStateService = inject(FdStateService);
  protected readonly themeService = inject(ThemeService);
  private readonly casterApiService = inject(CasterApiService);
  private readonly dialog = inject(MatDialog);

  readonly AppEnvironment = AppEnvironment;
  readonly RouteConstants = AppRoutes;
  readonly queryMinutes = [5, 10, 30];

  clientEnvironment = environment.configuration;
  refreshAccountSubscription: Subscription;

  /** Stores all the roles associated with the current route for easy testing. Only applicable in non-production environments. */
  applicableRoles: UserRoles[] = [];

  get isCustom() {
    return !this.queryMinutes.includes(this.fdStateService.queryMinutes);
  }
  @ViewChild("queryMinutesInput", { static: false }) queryMinutesInput: MatOption;

  get fullName(): string {
    return this.authService.currentUser
      ? `${this.authService.currentUser.firstName} ${this.authService.currentUser.lastName}`
      : "";
  }

  get isImpersonatingUser(): boolean {
    return this.authService.currentUser?.impersonatingIdentityId ? true : false;
  }

  get serverEnvironment() {
    return AppEnvironment[this.cookieService.get("Environment") as keyof typeof AppEnvironment]; // the server responds with the initial page load with an Environment cookie
  }

  get showLogin(): boolean {
    return this.router.url !== AppRoutes.login.routerLink && !this.authService.isLoggedIn;
  }

  constructor() {
    super();

    // subscribe to fragment events to re-open toolbar dialogs
    this.fdRouterService.fragment$.subscribe(route => {
      switch (route?.pathParts[0]) {
        case OptionsDialogComponent.hashId:
          this.openSettings();
          break;
      }
    });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.authService
        .refreshUser()
        .pipe(
          catchError(err => {
            if (err.status === 401) {
              this.signOut();
            }

            return of(false);
          })
        )
        .subscribe();
    }

    this.finalizeInit();

    if (
      this.drawer.opened &&
      this.localStorage.exists(DbKeys.DrawerOpen) &&
      !this.localStorage.getDataObject<boolean>(DbKeys.DrawerOpen)
    ) {
      this.drawer.toggle();
    }

    if (environment.configuration !== AppEnvironment.Production) {
      // don't show the roles picker in production
      this.subscriptions.push(
        this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router.routerState.root),
            map(route => {
              while (route.firstChild) {
                route = route.firstChild;
              }

              return route;
            }),
            mergeMap(route => route.data)
          )
          .subscribe((data: RouteInfoData) => {
            this.applicableRoles = [data.roleView, ...(data.additionalRoles ?? [])].filter(x => x);
          })
      );
    }

    // This delay is not desired but the #queryMinutesInput template reference isn't available due to the ngIf
    setTimeout(() => this.overrideQueryMinutes(), 500);

    this.fdStateService.onQueryMinutes.subscribe(_ => this.overrideQueryMinutes());
  }

  signOut() {
    this.authService.signOut().subscribe();
    this.authService.redirectLogoutUser();
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.localStorage.saveData(this.drawer.opened, DbKeys.DrawerOpen);
  }

  abortImpersonate() {
    this.authService.abortImpersonate().subscribe();
  }

  impersonateRole(reset: boolean, role?: UserRoles) {
    this.authService.impersonateRole(reset, role).subscribe();
  }

  public openSettings() {
    this.dialog
      .open(OptionsDialogComponent, {
        backdropClass: "align-top",
        data: {},
      })
      .beforeClosed()
      .subscribe(() => {
        //this.fdRouterService.replaceFragment();
      });
  }

  queryMinutesChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = Math.max(Math.min(parseInt(target.value), 480), 1);
    if (value) {
      this.fdStateService.queryMinutes = value;
      this.overrideQueryMinutes();
      this.fdStateService.queryMinutesChanged();
    } else {
      target.value = this.fdStateService.queryMinutes.toString();
    }
  }

  // We are setting this so that the custom selection is retained.
  private overrideQueryMinutes() {
    if (this.isCustom && this.queryMinutesInput != undefined) {
      this.queryMinutesInput.value = this.fdStateService.queryMinutes;
      this.queryMinutesInput.select();
    }
  }

  private finalizeInit() {
    //this.authService.reLoginDelegate = () => {};

    this.authService.getLoginStatusEvent().subscribe(loginStatus => {
      if (this.authService.isLoggedIn) {
        if (!this.refreshAccountSubscription) {
          this.refreshAccountSubscription = this.accountHubService.onRefresh().subscribe();
        }
        if (loginStatus) {
          this.fdStateService.$casters = this.casterApiService
            .get(
              this.userContext.isInRole(this.UserRoles.Admin_ViewAllCustomerData)
                ? null
                : this.userContext.customerGroupId,
              undefined,
              this.userContext.userId
            )
            .pipe(
              tap(casters => (this.fdStateService.caster = casters.find(s => s.id == this.fdStateService.casterId))),
              shareReplay()
            );
        }
      } else {
        this.refreshAccountSubscription?.unsubscribe();
        this.refreshAccountSubscription = null;
      }
    });
  }
}
