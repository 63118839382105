import { Component, Input } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AppRoutes } from "src/app/routes";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-navigation",
  templateUrl: "./app-navigation.component.html",
  styleUrl: "./app-navigation.component.scss",
})
export class AppNavigationComponent extends BaseComponent {
  @Input() appTitle: string;
  @Input() drawer: MatSidenav;

  root = AppRoutes.root;

  get logoUri(): string {
    // TODO: In dark mode, potentially return a different image
    // This might be better to move into the environment or a higher level config somewhere
    return "assets/images/logo-color.png";
  }
}
