import { EventEmitter, Injectable, inject } from "@angular/core";
import { LocalStoreManager } from "@fd/core";
import { Observable, tap } from "rxjs";
import { CasterInterface } from "../modules/admin/models/caster.model";
import { DbKeys } from "../modules/shared/enums/db-keys.enum";
import { AuthService } from "../modules/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class FdStateService {
  authService = inject(AuthService);
  localStoreManager = inject(LocalStoreManager);

  readonly defaults = {
    queryMinutes: 5,
    showOutliers: false,
    timeFormat24hour: true,
  };
  isDrawerOpen: boolean;
  caster: CasterInterface;
  casterId: number;
  onCasterId = new EventEmitter<number>();
  plantId: number;
  onPlantId = new EventEmitter<number>();
  queryMinutes: number = this.defaults.queryMinutes;
  onQueryMinutes = new EventEmitter<{ value: number; update: boolean }>();
  showOutliers: boolean = this.defaults.showOutliers;
  onShowOutliers = new EventEmitter<boolean>();
  timeFormat24hour: boolean = this.defaults.timeFormat24hour;
  $casters: Observable<CasterInterface[]>;

  showDashboardOptions: boolean = false;
  showCasters: boolean = true;

  constructor() {
    this.casterId =
      this.localStoreManager.getDataObject<number>(DbKeys.CASTER_ID) || this.authService.currentUser?.casterId;
    this.plantId =
      this.localStoreManager.getDataObject<number>(DbKeys.PLANT_ID) || this.authService.currentUser?.plantId;

    this.authService.onLogin
      .pipe(
        tap(user => {
          this.casterId = user.casterId;
          this.plantId = user.plantId;
          this.localStoreManager.saveSessionData(this.casterId, DbKeys.CASTER_ID);
          this.localStoreManager.saveSessionData(this.plantId, DbKeys.PLANT_ID);
        })
      )
      .subscribe();
  }

  casterChanged() {
    const plantChanged = this.plantId !== this.caster.plantId;
    this.casterId = this.caster.id;
    this.plantId = this.caster.plantId;
    this.localStoreManager.saveSessionData(this.casterId, DbKeys.CASTER_ID);
    this.localStoreManager.saveSessionData(this.plantId, DbKeys.PLANT_ID);

    this.onCasterId.emit(this.casterId);
    if (plantChanged) {
      this.onPlantId.emit(this.plantId);
    }
  }

  queryMinutesChanged() {
    this.onQueryMinutes.emit({ value: this.queryMinutes, update: true });
  }

  showOutliersChanged() {
    this.showOutliers = !this.showOutliers;
    this.onShowOutliers.emit(this.showOutliers);
  }
}
