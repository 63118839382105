import { ApplicationRef, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SwUpdate } from "@angular/service-worker";
import { FdSnackBar } from "@fd/core";
import { concat, from, interval } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";
import { UpdateDialogComponent } from "../components/update-dialog/update-dialog.component";
@Injectable({
  providedIn: "root",
})
export class SwUpdateService {
  private isInitialized = false;

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private snackBar: FdSnackBar,
    private dialog: MatDialog
  ) {}

  initialize() {
    if (!this.isInitialized) {
      this.checkForUpdates();
      this.promptUpdate();
      this.handleUnrecoverableState();
      this.isInitialized = true;
    }
  }

  private checkForUpdates() {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const interval$ = interval(30 * 1000);
    const onIntervalOnceAppIsStable$ = concat(appIsStable$, interval$);

    onIntervalOnceAppIsStable$.pipe(switchMap(() => from(this.updates.checkForUpdate()))).subscribe();
  }

  private promptUpdate() {
    const $versionReady = this.updates.versionUpdates.pipe(filter(event => event.type === "VERSION_READY"));

    $versionReady.subscribe(() => {
      this.dialog.open(UpdateDialogComponent, {
        data: from(this.updates.activateUpdate()),
      });
    });
  }

  private handleUnrecoverableState() {
    const $unrecoverableState = this.updates.unrecoverable.pipe(
      switchMap(() =>
        this.snackBar
          .open("An error occurred that we cannot recover from" + "\n\nPlease reload the page.")
          .afterDismissed()
      )
    );

    $unrecoverableState.subscribe(() => document.location.reload());
  }
}
