import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FdCoreModule, FdDateModule, FdMatPaginatorIntl, FdSpinnerComponent, LoadingLabelComponent } from "@fd/core";
import { MtxDatetimepickerModule } from "@ng-matero/extensions/datetimepicker";
import { MatTableExporterModule } from "mat-table-exporter";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppNavigationComponent } from "./components/app-navigation/app-navigation.component";
import { NavItemComponent } from "./components/app-navigation/nav-item/nav-item.component";
import { NestedNavItemComponent } from "./components/app-navigation/nested-nav-item/nested-nav-item.component";
import { AppToolbarComponent } from "./components/app-toolbar/app-toolbar.component";
import { EnvironmentChipComponent } from "./components/app-toolbar/environment-chip/environment-chip.component";
import { AppComponent } from "./components/app.component";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { OptionsDialogComponent } from "./components/options-dialog/options-dialog.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { UpdateDialogComponent } from "./components/update-dialog/update-dialog.component";
import { AuthService } from "./modules/shared/services/auth.service";
import { ThemeService } from "./modules/shared/services/theme.service";
import { SharedModule } from "./modules/shared/shared.module";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    EnvironmentChipComponent,
    AppNavigationComponent,
    AppToolbarComponent,
    RedirectComponent,
    NavItemComponent,
    NestedNavItemComponent,
    OptionsDialogComponent,
    UpdateDialogComponent,
  ],
  imports: [
    // FD Core
    FdCoreModule.forRoot(
      { authService: AuthService },
      {
        validationMessage: {
          notUnique: "This value is already in use.",
          invalidThresholdMode: "You can't set a proportional range when the target is 0.",
          invalidDialMetric: "You must select a metric for each dial.",
          invalidReasonName: "An other reason is included by default.",
          invalidIpAddress: "Invalid IP Address.",
        },
      }
    ),
    FdDateModule.forRoot(),

    // App
    AppRoutingModule,
    SharedModule,

    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Angular Material
    MatButtonModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSelectModule,
    MatSlideToggleModule,

    LayoutModule,

    // @ng-matero/extensions
    MtxDatetimepickerModule,

    // forRoot()
    MatTableExporterModule.forRoot({ xlsxLightWeight: true }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !environment.localServerUrl.includes(location.host.split(":")[0]),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),

    // Standalone Components
    FdSpinnerComponent,
    LoadingLabelComponent,
  ],
  providers: [
    // Other
    CookieService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: "right",
        verticalPosition: "bottom",
      },
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: environment.matPaginatorDefaultOptions,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: environment.matFormFieldDefaultOptions,
    },
    { provide: MatPaginatorIntl, useClass: FdMatPaginatorIntl },
  ],
})
export class AppModule {
  constructor() {
    inject(ThemeService).initialize();
  }
}
