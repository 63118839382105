<div class="container-xs surface mt-5">
  <div class="flex-spaced align-items-center justify-content-between w-100">
    <h2 class="m-0">Not Authorized</h2>
    <mat-icon color="warn">error_outline</mat-icon>
  </div>
  <p class="my-4">
    You are not authorized to access the requested page. Please contact a site administrator to provide you access.
  </p>

  <div class="flex-spaced justify-content-end">
    <a *ngIf="previousUrl" mat-button color="accent" [routerLink]="previousUrl">Previous page</a>
    <a mat-stroked-button color="accent" routerLink="/">Back to home</a>
  </div>
</div>
