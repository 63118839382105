import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { AccessListInterface } from "../models/access-list.model";

@Injectable({ providedIn: "root" })
export class AccessListApiService extends ControllerBase<AccessListInterface> {
  constructor() {
    super("Common/AccessList");
  }

  //#region super implementation
  get(ip: string = ""): Observable<AccessListInterface> {
    return super.$getById(ip);
  }

  put(model: AccessListInterface) {
    return super.$put(model);
  }

  delete(id: string = "") {
    return super.$delete(id);
  }
  //#endregion
}
