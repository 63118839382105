import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { IFormGroup, RxFormBuilder } from "@rxweb/reactive-form-validators";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { BaseComponent } from "src/app/components/base.component";
import { AccessListInterface, AccessListItem } from "src/app/modules/common/models/access-list.model";
import { AccessListApiService } from "src/app/modules/common/services/access-list-api.service";

@Component({
  selector: "app-access-list",
  templateUrl: "./access-list.component.html",
  standalone: true,
  imports: [MatFormFieldModule, CommonModule, ReactiveFormsModule, MatInputModule, MatButtonModule],
})
export class AccessListComponent extends BaseComponent {
  item: Partial<AccessListInterface> = { status: "Unknown" };

  formGroup: IFormGroup<AccessListItem>;

  constructor(private accessListApiService: AccessListApiService, formBuilder: RxFormBuilder) {
    super();

    // create a class object out of the interface for decorator validation
    this.formGroup = formBuilder.formGroup(AccessListItem) as IFormGroup<AccessListItem>;
    this.getStatus().subscribe();
    this.subscriptions.push(
      this.formGroup.controls.ip.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          switchMap(_ => this.getStatus())
        )
        .subscribe()
    );
  }

  private getStatus() {
    return this.accessListApiService.get(this.formGroup.value.ip ? this.formGroup.value.ip : undefined).pipe(
      tap(status => {
        this.formGroup.patchValue(status, { emitEvent: false });
        this.item = status;
      })
    );
  }

  submit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid && !this.formGroup.pristine) {
      this.accessListApiService
        .put(this.formGroup.value)
        .pipe(switchMap(_ => this.getStatus()))
        .subscribe();
    }
  }

  delete() {
    this.accessListApiService
      .delete(this.item.ip)
      .pipe(switchMap(_ => this.getStatus()))
      .subscribe();
  }
}
