import { EventEmitter, Injectable, inject } from "@angular/core";
import { LocalStoreManager } from "@fd/core";
import { environment } from "src/environments/environment";
import { DbKeys } from "../enums/db-keys.enum";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private readonly environment = environment;
  private readonly localStoreManager = inject(LocalStoreManager);

  private theme: "light" | "dark" = "light";

  onLightModeChange = new EventEmitter<boolean>();

  get activeTheme() {
    return this.theme;
  }

  get themesEnabled() {
    return !!this.environment?.theme.supportsDarkMode;
  }

  get isLightMode() {
    return this.theme == "light";
  }

  initialize() {
    this.theme = this.localStoreManager.getData(DbKeys.Theme) || "light";
    this.applyThemeClass();
  }

  toggleTheme() {
    this.theme = this.theme === "dark" ? "light" : "dark";
    this.localStoreManager.saveSessionData(this.theme, DbKeys.Theme);
    this.applyThemeClass();
    this.onLightModeChange.emit(this.isLightMode);
  }

  private applyThemeClass() {
    document.body.classList.toggle("theme-light", this.theme === "light");
    document.body.classList.toggle("theme-dark", this.theme === "dark");
    // TODO: This is using a custom implementation and .theme-alternate. The associated code should be moved to .theme-dark
    document.body.classList.toggle("theme-alternate", this.theme === "dark");
  }
}
